import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import { computeShipping, computeTax, computeTotal, BAND_TYPE, SILVER_SET_TYPES } from '../common/checkout'
import Cache from './cache'
import finalPrice from '../helpers/product/finalPrice'

const MAX_ITEMS = 10
const MAX_AGE = 24 * 60 * 60 * 1000 // 24 hours in milliseconds

const taxDataCache = new Cache(MAX_ITEMS, MAX_AGE)
const couponDataCache = new Cache(MAX_ITEMS, MAX_AGE)

const CHECKOUT_STATUS_INITIAL = 'initial'
const CHECKOUT_STATUS_FAILED = 'failed'
const CHECKOUT_STATUS_SUCCESS = 'success'
const CHECKOUT_STATUS_IDLE = 'idle'
const CHECKOUT_STATUS_LOADING = 'loading'

const initCart = {
  products: [],
  surcharge: 0,
  shipping: 0,
  tax: 0,
  total: 0,
  tax_rate: 0,
  tax_shipping: false,
  status: CHECKOUT_STATUS_INITIAL,
  coupon_status: CHECKOUT_STATUS_IDLE,
  coupon_result: {
    free_shipping: false,
    discount: 0,
    code: '',
    description: '',
    ticker: ''
  },
  status_popup: false,
  status_message: '',
  tax_estimation: {},
  // status_highlight: '',
  errors: [],
}

export const applyCoupon = createAsyncThunk(
  'cart/applyCoupon',
  async ({ cart, checkout }, thunkAPI) => {
    try {
      // if (
      //   (checkout.shipping_address || '').trim().length < 2 ||
      //   (checkout.shipping_city || '').trim().length < 2 ||
      //   (checkout.shipping_state || '').trim().length < 2 ||
      //   (checkout.shipping_zip || '').trim().length < 5
      // ) {
      //   console.log('Reject coupon - invalid address', cart, checkout)
      //   return thunkAPI.rejectWithValue({ error: 'Please enter a valid shipping address' })
      // }
      const sanitized = {
        checkout: {
          shipping_address: (checkout.shipping_address||'').trim(),
          shipping_address2: (checkout.shipping_address2 || '').trim(),
          shipping_city: (checkout.shipping_city||'').trim(),
          shipping_state: (checkout.shipping_state||'').trim(),
          shipping_zip: (checkout.shipping_zip||'').trim(),
          coupon_code: checkout.coupon_code,
        },
        cart: {
          products: cart.products.map(item => {
            return {
              sku: item.sku,
              qty: item.qty,
              price: finalPrice(item),
              regular_price: item.price,
              special_price: item.special_price,
            }
          }),
          total: cart.total,
          shipping: cart.shipping,
        },
      }

      const cacheKey = JSON.stringify(sanitized)
      const cachedData = couponDataCache.get(cacheKey)
      if (cachedData) {
        console.log('Using cached data')
        return cachedData
      }

      const response = await fetch('/api/apply-coupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sanitized),
      })
      let error = 'Could not validate coupon code'
      if (response && response.status === 200) {
        const couponData = await response.json()
        if (couponData.success) {
          couponDataCache.set(cacheKey, couponData.result)
          return couponData.result
        } else {
          error = couponData.error || error
        }
      }
      return thunkAPI.rejectWithValue({ error: error.replaceAll('to_', '') })
    } catch (error) {
      console.error(error)
      return thunkAPI.rejectWithValue({ error: error.message ? error.message : error })
    }
  }
)

export const calculateTax = createAsyncThunk(
  'cart/calculateTax',
  async ({ cart, checkout }, thunkAPI) => {
    try {
      if (
        (checkout.shipping_address || '').trim().length < 2 ||
        (checkout.shipping_city || '').trim().length < 2 ||
        (checkout.shipping_state || '').trim().length < 2 ||
        (checkout.shipping_zip || '').trim().length < 5
      ) {
        return thunkAPI.rejectWithValue({ error: 'Please enter a valid shipping address' })
      }
      const sanitized = {
        checkout: {
          shipping_address: checkout.shipping_address.trim(),
          shipping_address2: (checkout.shipping_address2 || '').trim(),
          shipping_city: checkout.shipping_city.trim(),
          shipping_state: checkout.shipping_state.trim(),
          shipping_zip: checkout.shipping_zip.trim(),
          payment_method: checkout.payment_method,
        },
        cart: {
          products: cart.products.map(item => {
            return {
              sku: item.sku,
              qty: item.qty,
              price: finalPrice(item),
              regular_price: item.price,
              special_price: item.special_price,
              ask_for_price: item.ask_for_price,
            }
          }),
          total: cart.total,
          shipping: cart.shipping,
        },
      }
      const cacheKey = JSON.stringify(sanitized)
      const cachedData = taxDataCache.get(cacheKey)
      if (cachedData) {
        return cachedData
      }

      const response = await fetch('/api/confirm-tax', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sanitized),
      })
      let error = 'Could not calculate tax'
      if (response && response.status) {
        const taxData = await response.json()
        if (taxData.success) {
          taxDataCache.set(cacheKey, taxData.result)
          return taxData.result
        } else {
          error = taxData.error ? 'Tax and address validation failed: ' + taxData.error : error
        }
      }
      return thunkAPI.rejectWithValue({ error: error.replaceAll('to_', '') })
    } catch (error) {
      console.error(error)
      return thunkAPI.rejectWithValue({ error: error.message ? error.message : error })
    }
  }
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState: initCart,
  reducers: {
    clearCart: (state) => {
      state = { ...initCart }
      return state
    },
    showStatusPopup: (state, action) => {
      state.status_popup = action.payload.show !== false
      if (action.payload.highlight) {
        state.status_highlight = action.payload.highlight || ''
      }
    },
    changeStatus: (state, action) => {
      state.status = action.payload.status
      state.status_message = action.payload.message || ''
      state.errors = action.payload.errors || []
      if (action.payload.show !== undefined) {
        state.status_popup = action.payload.show !== false
      }
      if (action.payload.highlight) {
        state.status_highlight = action.payload.highlight || ''
      }
    },
    updateTaxEstimation: (state, action) => {
      state.tax_estimation = action.payload
    },
    updateTax: (state, action) => {
      const { tax, tax_rate, tax_shipping } = action.payload
      state.tax = tax
      state.tax_rate = tax_rate
      state.tax_shipping = tax_shipping
    },
    resetCoupon: state => {
      state.coupon_status = CHECKOUT_STATUS_IDLE
      state.coupon_result = {
        free_shipping: false,
        discount: 0,
      }
    },
    addProduct: (state, action) => {
      const {
        sku,
        price,
        special_price,
        name,
        title,
        image,
        url,
        brand,
        model,
        model_no,
        color,
        size,
        qty,
        avail_qty,
        item_type,
        item_category,
        ask_for_price,
        description,
        media_gallery,
        categories
      } = action.payload
      const index = state.products.findIndex(product => product.sku === sku)
      if (index === -1) {
        state.products.push({
          sku: sku,
          price: price,
          special_price: special_price,
          name: name,
          title: title,
          image: image,
          url: url,
          brand: brand,
          model: model,
          model_no: model_no,
          color: color,
          size: size,
          item_type: item_type,
          item_category: item_category,
          ask_for_price: ask_for_price,
          qty: Math.min(avail_qty || qty || 1, qty || 1),
          description: description,
          media_gallery: media_gallery,
          categories: categories
        })
      } else {
        const product = state.products[index]
        state.products[index].qty = avail_qty
          ? Math.min(avail_qty, product.qty + (qty || 1))
          : product.qty + (qty || 1)
      }
      const onlyBands = (state?.products || []).every(p => p.item_type === BAND_TYPE)
      const silverSets = (state?.products || []).filter(p => SILVER_SET_TYPES.includes(p.item_category)).length > 0
      // console.log('Jewlery types', state?.products.map(p => p.jewelry_type))
      state.total = computeTotal(state)
      state.shipping = computeShipping(state.total, onlyBands, silverSets)
      // total, shipping, tax_rate, tax_shipping
      state.tax = computeTax(state.total, state.shipping, state.tax_rate, state.tax_shipping)
    },
    removeProduct: (state, action) => {
      const { sku, qty } = action.payload
      const index = state.products.findIndex(p => p.sku === sku)
      const skus = state.products.map(p => p.sku)
      if (index === -1) {
        console.warn(`product not found '${sku}' in '${skus}'`, current(state))
        return
      }
      const product = state.products[index]
      if (qty && qty < product.qty) {
        state.products[index].qty -= qty
      } else {
        state.products.splice(index, 1)
      }
      const onlyBands = (state?.products || []).every(p => p.item_type === BAND_TYPE)
      const silverSets = (state?.products || []).filter(p => SILVER_SET_TYPES.includes(p.item_category)).length > 0
      state.total = computeTotal(state)
      state.shipping = computeShipping(state.total, onlyBands, silverSets)
      state.tax = computeTax(state.total, state.tax, state.tax_rate, state.tax_shipping)
    },
  },
  extraReducers: {
    [applyCoupon.fulfilled]: (state, action) => {
      console.log('Got coupon data', action.payload)
      state.status = CHECKOUT_STATUS_IDLE
      state.status_message = ''
      state.coupon_status = CHECKOUT_STATUS_SUCCESS
      state.coupon_result = action.payload
    },

    [applyCoupon.pending]: state => {
      state.status = CHECKOUT_STATUS_LOADING
      state.status_message = ''
    },
    [applyCoupon.rejected]: (state, action)  => {
      console.log('Change status [coupon]', CHECKOUT_STATUS_FAILED)
      state.status = CHECKOUT_STATUS_FAILED
      state.status_message = action.payload.error ||'Invalid coupon code'
      state.status_popup = true
      state.coupon_status = CHECKOUT_STATUS_FAILED
      state.coupon_result = {
        free_shipping: false,
        discount: 0,
      }
    },
    [calculateTax.fulfilled]: (state, action) => {
      console.log('Got tax data', action.payload)
      state.tax = action.payload.tax
      state.tax_rate = action.payload.tax_rate
      state.tax_shipping = action.payload.tax_shipping
      state.status = CHECKOUT_STATUS_IDLE
      state.status_message = ''
    },
    [calculateTax.pending]: state => {
      console.log('Calculating tax')
      state.status = CHECKOUT_STATUS_LOADING
    },
    [calculateTax.rejected]: (state, action) => {
      console.log('Change status [tax]', CHECKOUT_STATUS_FAILED)
      console.log('Got error', action.payload)
      state.status = CHECKOUT_STATUS_FAILED
      state.status_message = action.payload.error
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  addProduct,
  removeProduct,
  updateTax,
  changeStatus,
  showStatusPopup,
  resetCoupon,
  updateTaxEstimation,
  clearCart
} = cartSlice.actions
export {
  initCart,
  CHECKOUT_STATUS_FAILED,
  CHECKOUT_STATUS_INITIAL,
  CHECKOUT_STATUS_SUCCESS,
  CHECKOUT_STATUS_LOADING,
  CHECKOUT_STATUS_IDLE,
}

export default cartSlice.reducer
