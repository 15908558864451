// noinspection JSUnusedGlobalSymbols,CommaExpressionJS,ES6ConvertVarToLetConst
import GoogleAds, { GoogleAdsId, GA4Id, CriteoPartnerID } from './utils/GoogleAds'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import wrapWithProvider from './redux-provider'
import NProgress from 'nprogress'

/** 
 * Use one of the below
 * NOTE: comment out attribution part if in regular mode
**/
// ** [Regular] Used to find Real User Experience (use for regular performance monitoring) 
// import { onLCP, onINP, onCLS } from 'web-vitals'
// ** [Attribution] Used to find Real User Experience element causing the most problem (use to debug issues)
import { onLCP, onINP, onCLS,  onFCP, onTTFB } from 'web-vitals/attribution'
import { TrackEvent, TrackUser, PersonalInfo, CheckTrackedUser, SendCriteoEvent } from './src/helpers/TrackingHelper'

// const initRudderStack = () => {
//   window.rudderanalytics.loadJS()
// }

const initScripts = () => {
  // console.log(`Init scripts [${!window.scriptsDidInit}], after ${(new Date().getTime() - window.started)} milliseconds`)
  if (window.scriptsDidInit) {
    return false
  }
  window.scriptsDidInit = true // flag to ensure script does not get added to DOM more than once.
  // initRudderStack()
  // Init Klavyio
  addScript('//static.klaviyo.com/onsite/js/klaviyo.js?company_id=JHEYDB', () => {
    addKlaviyoFormsTracking()
  })
  addScript('//cdn.callrail.com/companies/267670386/7ad9f09f303977651ce0/12/swap.js')
  addScript(`//www.googletagmanager.com/gtag/js?id=${GA4Id}`)
  // initGTM(window, 'dataLayer', 'GTM-K25RWJ')
  // addScript(`//www.googletagmanager.com/gtag/destination?id=${GoogleAdsId}&l=dataLayer&cx=c`)
  initBing(window, 'uetq', '187029939')
  if(window.tng) window.tng.runLoad()
  initCoreWebVitals()
  initCriteo()
  setTimeout(CheckTrackedUser , 500)
}

const sendToGoogleAnalytics = ({ name, delta, value, id, attribution }) => {
  const eventParams = {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.

    // Custom params with descriptive names:
    web_vitals_id: id, // Needed to aggregate events.
    web_vitals_value: value, // The metric value.
    web_vitals_delta: delta, // The delta between values.
    web_vitals_load_state: attribution?.loadState || '',
  }

  // NOTE: comment out if in regular mode
  switch (name) {
    case 'CLS':
      eventParams.web_vitals_debug_target = attribution?.largestShiftTarget
      break
    case 'INP':
      eventParams.web_vitals_debug_target = attribution?.interactionTarget
      eventParams.web_vitals_debug_interaction_type = attribution?.interactionType
      eventParams.web_vitals_input_delay = attribution?.inputDelay
      eventParams.web_vitals_processing_duration = attribution?.processingDuration
      eventParams.web_vitals_presentation_delay = attribution?.presentationDelay
      // console.log(`INP ${eventParams.web_vitals_value} Attribution Events`, attribution?.processedEventEntries.map(e => ({
      //   time: e.processingEnd - e.processingEnd,
      //   timeSinceStart: e.processingEnd - e.startTime,
      //   entryType: e.entryType,
      //   name: e.name,
      //   details: e
      // })))
      break
    case 'LCP':
      eventParams.web_vitals_debug_target = attribution?.element
      break
  }

  // Send the event to Google Analytics
  //
  // console.log(
  //   `[CoreWebVitals] ${name}: ${value} (+${delta}) <${eventParams.web_vitals_debug_target}>`,
  //   {
  //     location: window?.location?.pathname,
  //     attribution,
  //   }
  // )
  if (window.gtag) {
    window.gtag('event', name, eventParams)
  }
}

const initCoreWebVitals = ()  => {
  onCLS(sendToGoogleAnalytics)
  onINP(sendToGoogleAnalytics, { reportAllChanges: true })
  onLCP(sendToGoogleAnalytics)

  onFCP(sendToGoogleAnalytics)
  onTTFB(sendToGoogleAnalytics)
}

const addKlaviyoFormsTracking = () => {
  window.addEventListener('klaviyoForms', function (e) {
    /* Possible values for e.detail.type:
     * Read: https://help.klaviyo.com/hc/en-us/articles/115005077027
     * ```
    if (e.detail.type == 'open' || e.detail.type == 'embedOpen') {
      gtag('event', 'form_open', { form: 'Klaviyo form', form_id: e.detail.formId })
    }
    if (e.detail.type == 'submit') {
      gtag('event', 'form_submit', { form: 'Klaviyo form', form_id: e.detail.formId })
    }
    if (e.detail.type == 'stepSubmit') {
      gtag('event', 'form_step_submit', {
        form: 'Klaviyo form',
        step_name: e.detail.metaData.$step_name,
      })
    }
    if (e.detail.type == 'redirectedToUrl') {
      gtag('event', 'form_url_redirect', { form: 'Klaviyo form', form_id: e.detail.formId })
    }
    if (e.detail.type == 'close') {
      gtag('event', 'form_close', { form: 'Klaviyo form', form_id: e.detail.formId })
    } ```
    */
    if (e?.detail?.type == 'submit') {
      const formId = e?.detail?.formId
      const formTitle =  e?.detail?.metaData?.$source
      const email = e?.detail?.metaData?.$email
      TrackUser(email, PersonalInfo({ ...e.detail, ...e?.detail?.metaData }))
      TrackEvent('email_subscribe',
        {
          email,
          source: 'Klaviyo',
          formId,
          formTitle,
        },
        {
          klaviyoEventName: 'Email Subscribe',
          googleAdsConversion: GoogleAds.Conversions.email_subscribe,
        }
      )
    }
  })
}
const initScriptsOnIdle = () => {
  if ('requestIdleCallback' in window) {
    requestIdleCallback(() => initScripts())
  } else {
    setTimeout(initScripts, 250) // Fallback if `requestIdleCallback` isn't supported
  }
}

const initScriptsOnEvent = () => {
  // console.log(`${event.type}, after ${(new Date().getTime() - window.started)} milliseconds`)
  initScriptsOnIdle()
  // ** remove specific event listener
  // event.currentTarget.removeEventListener(event.type, initScriptsOnEvent)
  unregisterListeners()
}
const getQueryString = () => {
  const item = window.sessionStorage.getItem('qs')
  if (!item) return {}
  try {
    const qs = JSON.parse(item)
    window.qs = qs
    return qs
  } catch {
    return {}
  }
}
const setQueryString = qs => {
  window.sessionStorage.setItem('qs', JSON.stringify(qs))
  window.qs = qs
}
const addScriptExecutor =  (doc, tag, src, onLoad) => {
  let head = doc.getElementsByTagName(tag)[0]
  let scrpt = doc.createElement(tag)
  scrpt.async = true
  scrpt.src = src 
  if(onLoad) {
    scrpt.onload = scrpt.onreadystatechange = function () {
      let ready = this.readyState
      if (!ready || ready === 'loaded' || ready === 'complete') {
        onLoad()
        scrpt.onload = scrpt.onreadystatechange = null
      }
    }
  }
  head.parentNode.insertBefore(scrpt, head)
}
const addScript = (src, onLoad) => {
  addScriptExecutor(document, 'script', src, onLoad)
}
// const initGTM = (wnd, layer, id) => {
//   wnd[layer] = wnd[layer] || []
//   wnd[layer].push({
//     'gtm.start': new Date().getTime(),
//     event: 'gtm.js',
//   })
//   let dl = layer != 'dataLayer' ? '&l=' + layer : ''
//   addScript('//www.googletagmanager.com/gtm.js?id=' + id + dl)
// }

const initBing = (wnd, layer, id) => {
  wnd[layer] = window[layer] || []
  let onLoad = function () {
    let cnf = { ti: id, enableAutoSpaTracking: true }
    cnf.q = window[layer]
    window[layer] = new window.UET(cnf)
    window[layer].push('pageLoad')
  }
  addScript('//bat.bing.com/bat.js', onLoad)
}

const initCriteo = () => {
  addScript(`//dynamic.criteo.com/js/ld/ld.js?a=${CriteoPartnerID}`)
}

export const onClientEntry = () => {
  window.dataLayer = window.dataLayer || []
  window._learnq = window._learnq || []
  window.criteo_q = window.criteo_q || []
  window.uetq = window.uetq || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  window.gtag = window.gtag || gtag
  
  const tng = (window.tng = window.tng || [])

  let deviceType = /iPad/.test(navigator.userAgent)
    ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
    ? 'm' : 'd'
  let initial_page = window.sessionStorage.getItem('initial_page')
  if (!initial_page) {
    window._initial_page = window.location.href
    window.sessionStorage.setItem('initial_page', window._initial_page)
  } else {
    window._initial_page = initial_page
    window._device_type = deviceType
  }

  // Configure gtag with your GA4 and Google Ads IDs
  gtag('js', new Date())
  gtag('config', GA4Id)
  gtag('config', GoogleAdsId)
  // 
  // gtag('config','G-LHPEQ813PL') 
  // gtag('config','G-1S8B9HGMB3') 
  // gtag('config','GT-WV8WQ69')

  tng.runLoad = function () {}
  tng.load = function () {
    if (tng.loaded) return
    tng.runLoad = tng.load
    if (!window.scriptsDidInit) return
    tng.loaded = true
    addScript(
      '//cdn.tangiblee.com/integration/5.0/managed/www.grayandsons.com/revision_1/variation_original/tangiblee-bundle.min.js'
    )
  }
}

const unregisterListeners = () => {
  window.removeEventListener('load', initScriptsOnEvent)
  document.removeEventListener('scroll', initScriptsOnEvent)
  document.removeEventListener('mousemove', initScriptsOnEvent)
  document.removeEventListener('touchstart', initScriptsOnEvent)
}

export const onInitialClientRender = () => {
  // console.log('Initial Render, document state:', document.readyState)
  // window.started = new Date().getTime()
  // document.addEventListener('DOMContentLoaded', () => {
  //   console.log(`DOMContentLoaded, after ${new Date().getTime() - window.started} milliseconds`)
  // })
  // window.addEventListener('load', () => {
  //   console.log(`Window Load, after ${new Date().getTime() - window.started} milliseconds`)
  // })
  if (document.readyState === 'complete') {
    initScriptsOnIdle()
  } else {
    window.addEventListener('load', initScriptsOnEvent)
    document.addEventListener('scroll', initScriptsOnEvent)
    document.addEventListener('mousemove', initScriptsOnEvent)
    document.addEventListener('touchstart', initScriptsOnEvent)
  }
}

export const onPreRouteUpdate = () => {
  // console.log('Route Update')
  if (!window.scriptsDidInit) {
    return false
  }
  NProgress.start()
}
// On Route Update (it can accept location and prevLocation as arguments if you need them)
export const onRouteUpdate = () => {
  // console.log('Route Done')
  NProgress.done()
  const vars = getQueryString()
  if (location.search) {
    const query = location.search.substring(1)
    query.split('&').forEach(v => {
      const pair = v.split('=')
      if (pair.length > 1) {
        vars[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      } else {
        vars[decodeURIComponent(pair[0])] = null
      }
    })
    setQueryString(vars)
  }
  window?._learnq?.push(['track', 'Viewed a Page', { url: location.href }])
  window?._learnq?.push(['track', 'Active on Site', { url: location.href }])
  window?.gtag('event', 'page_view', { page_title: window.document.title, page_location: location.href })
  if(location.pathname === '/') {
    window?.gtag('event', 'home_view', {
      page_title: window.document.title,
      page_location: location,
    })
    SendCriteoEvent({ event: 'viewHome' })
  } else {
    SendCriteoEvent({ event: 'viewPage' })
  }
  // window?.rudderanalytics?.page()
}
export const wrapRootElement = wrapWithProvider
