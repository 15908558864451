import { calculateTax } from '../state/cart'
const recalculateTax = (storeAPI) => {
    const state = storeAPI.getState()
    const {
      shipping_address,
      // shipping_address2,
      shipping_zip,
      shipping_city,
      shipping_state,
    } = state.checkout
    if (!shipping_address || !shipping_zip || !shipping_city || !shipping_state || shipping_zip.length < 5) {
      return 
    }
    // Dispatch the calculateTax action with current state of the cart
    storeAPI.dispatch(calculateTax({ cart: state.cart, checkout: state.checkout }))

}
const taxApi = storeAPI => next => action => {
  // Call the next middleware (or reducer if this is the last middleware in chain)
  switch(action.type) {
    case 'cart/addProduct':
    case 'cart/removeProduct':
    case 'checkout/updateCheckoutField':
      recalculateTax(storeAPI)
  }
  return next(action)
}

export default taxApi
