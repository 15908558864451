
export default class Cache {
  constructor(maxItems, maxAge) {
    this.maxItems = maxItems
    this.maxAge = maxAge
    this.map = new Map()
    this.keys = []
  }

  get(key) {
    const now = Date.now()

    // Check if the item is too old.
    if (this.map.has(key) && now - this.map.get(key).timestamp > this.maxAge) {
      this.remove(key)
    }

    return this.map.has(key) ? this.map.get(key).data : undefined
  }

  set(key, value) {
    const now = Date.now()

    // Remove the oldest item if the cache is too large.
    if (this.keys.length >= this.maxItems) {
      this.remove(this.keys[0])
    }

    // Add the item to the cache.
    this.keys.push(key)
    this.map.set(key, { data: value, timestamp: now })
  }

  remove(key) {
    this.keys = this.keys.filter((k) => k !== key)
    this.map.delete(key)
  }
}

