const Validator = require('Validator')
const { BackendError } = require('../common/errors')

const toNumber = value => {
  if (!value || isNaN(value)) {
    return 0
  }
  return Number(value)
}

const computeTotal = state => {
  return state.products.reduce((total, arg) => total + (arg.special_price || arg.price || 0) * (arg.qty || 0), 0)
}

const computeTax = (total, shipping, tax_rate, tax_shipping) => {
  return ((total + (tax_shipping ? shipping : 0)) * tax_rate).toFixed(2)
}

const computeShipping = (total, onlyBands, silverSets) => {
  const min = silverSets ? 250 : onlyBands ? 25 : 80
  const max = 300
  const percentage = 0.013
  return Math.min(Math.max(min, (total) * percentage), max)
}

const computeGrandTotal = (total, shipping, tax, coupon, surcharge) => {
  return (
    toNumber(surcharge) +
    toNumber(total) +
    (toNumber(coupon.free_shipping) ? 0 : toNumber(shipping)) +
    toNumber(tax) -
    toNumber(coupon.discount)
  )
}

const BAND_TYPE = 'Watch Bands'
const SILVER_SET_TYPES = ['Sterling Silver Flatware']
const WATCH_TYPE = 'Watch'

const deepValidate = (state, rules, validation, path = '') => {
  let isArrayRule = key => key.indexOf('.*.') > -1
  let isObjectRule = (key, rules) => typeof rules[key] === 'object'
  let arrayRules = Object.keys(rules)
    .filter(key => isArrayRule(key))
    .map(key => {
      const info = key.indexOf('.*.')
      const arrayKey = key.substring(0, info)
      const ruleInfo = key.substring(info + 3)
      return { key: arrayKey, rule: ruleInfo }
    })
    .reduce((accumulator, item) => {
      accumulator[item.key] = accumulator[item.key] || {}
      accumulator[item.key][item.rule] = rules[item.key + '.*.' + item.rule]
      return accumulator
    }, {})
  let objectRules = Object.keys(rules).filter(key => isObjectRule(key, rules))
  let otherRules = Object.keys(rules).filter(key => !isArrayRule(key) && !isObjectRule(key, rules))
  validation = validation || {
    errors: [],
    passes: () => validation.errors.length == 0,
    getErrors: () => validation.errors,
  }

  const regularRules = otherRules.reduce((accumulator, key) => {
    accumulator[key] = rules[key]
    return accumulator
  }, {})
  const v = Validator.make(state, regularRules)
  if (!v.passes()) {
    validation.errors = validation.errors.concat(v.getErrors())
  }
  objectRules.forEach(key => {
    deepValidate(state[key], rules[key], validation, path + key + '.')
  })
  Object.keys(arrayRules).forEach(key => {
    const array = state[key]
    if (!Array.isArray(array)) {
      validation.errors.push(`${path}${key} is not an array`)
      return
    }
    array.forEach((item, index) => {
      const itemPath = `${path}${key}[${index}]`
      deepValidate(item, arrayRules[key], validation, itemPath + '.')
    })
  })
  return validation
}

const validateOrder = state => {
  const rules = {
    checkout: {
      shipping_method: 'required',
      shipping_name: 'required',
      shipping_last_name: 'required',
      shipping_address: 'required',
      // shipping_address2: 'present',
      shipping_country: 'required',
      shipping_state: 'required',
      shipping_city: 'required',
      shipping_zip: 'required',
      phone: 'required',
      email: 'required|email',
      billing_name:
        'required_with:billing_last_name,billing_address,billing_country,billing_state,billing_city,billing_zip,billing_phone',
      billing_last_name: 'required_with:billing_name',
      billing_address: 'required_with:billing_name',
      // billing_address2: 'present',
      billing_country: 'required_with:billing_name',
      billing_state: 'required_with:billing_name',
      billing_city: 'required_with:billing_name',
      billing_zip: 'required_with:billing_name',
      billing_phone: 'required_with:billing_name',
      payment_method: 'required|in:paypal,affirm,bankwire,creditcard,bitpay',
    },
    cart: 'required|array|min:1',
    'cart.*.sku': 'required',
    'cart.*.qty': 'required|integer|min:1',
  }

  let localState = { ...state }
  localState.checkout = {
    ...localState.checkout,
    payment_method: (localState.checkout.payment_method || '').toLowerCase(),
    shipping_method: (localState.checkout.shipping_method || 'usps').toLowerCase(),
    shipping_country: localState.checkout.shipping_country || 'US',
    billing_country: localState.checkout.billing_country || 'US',
  }

  const validation = deepValidate(localState, rules)

  if (!validation.passes()) {
    console.warn(validation.getErrors())
    throw new BackendError('Validation failed', 400, {
      errors: validation.getErrors(),
    })
  }
  return true
}

module.exports = {
  computeShipping,
  computeTotal,
  computeTax,
  BAND_TYPE,
  WATCH_TYPE,
  SILVER_SET_TYPES,
  validateOrder,
  computeGrandTotal,
  toNumber,
}
