import isOnSale from './isOnSale'

const finalPrice = (product) => {
  if (isOnSale(product)) {
    return product.special_price
  }
  return product.price
}

export default finalPrice
