module.exports = {
  GA4Id: 'G-1S8B9HGMB3',
  GoogleAdsId: 'AW-1061399325',
  CriteoPartnerID: '61488',
  Conversions: {
    // Empty - means no conversion tracking
    // Form Submissions
    make_an_offer: 'zR47CMmo644YEJ3WjvoD', // [x] Code, [x] GoogleAds, [ ] Tested, firing
    ask_for_details: 'S3wxCOuToM0YEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    buying_form: '19-4CK3qwuAZEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    ez_ship_box: 'g9tfCIW0ktAZEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    ez_ship_box_repairs: 'g9tfCIW0ktAZEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    catalog_request: 'pvQ0CInpjM8BEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    form_submission: '', // [x] Code, [ ] Google Ads, [ ] Tested, all other form submissions
    // Chat
    live_chat_started: 'i4nGCIH_wJ8ZEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    // Email Subscriptions
    email_subscribe: 'D4XFCNT8n80YEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    // Other Events
    email_clicked: 'Y5mhCIO2-4IZEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    phone_clicked: '', // [x] Code, [ ] Google Ads, [ ] Tested
    location_clicked: '', // [x] Code, [ ] Google Ads, [ ] Tested
    // Email Button click (needs to be implemented)
    other_non_sale: '0kA3CNmN6V4Qx9fnxAM', // [ ] Code, [ ] Google Ads, [ ] Tested
    // GA-ID: 949611463, Sell/Trade/Consign Thank You Page, Catalog Request Landing Page, Link Clicked (Email)
    // Ecommerce
    sale: 'vD91CNOjk44YEJ3WjvoD', // [x] Code, [x] Google Ads, [ ] Tested
    // Make sure to include conversion value, transaction_id, currency, and items
    added_to_cart: '', // [x] Code, [x] Google Ads (GA4), [ ] Tested
  },
}
