import { createSlice } from '@reduxjs/toolkit'

export const CHAT_STATE_HIDDEN = -2
export const CHAT_STATE_CLOSED = -1
export const CHAT_STATE_LOADING = 0
export const CHAT_STATE_ANY = 1
export const CHAT_STATE_OPEN = 2

export const popupForms = createSlice({
  name: 'popupForms',
  initialState: {
    started: false,
    makeAnOffer: false,
    product: {
      title: '',
      price: 0,
      special_price: 0,
      qty: 0,
      image: {
        url: '',
        ext: '',
        height: 0,
        width: 0,
      },
      sku: '',
      retail_price: 0,
      url: '',
    },

    offerSent: false,
    fullName: '',
    offerPrice: 0.0,
    email: '',
    phone: '',
    message: '',

    showPopup: false,
    chatState: CHAT_STATE_LOADING,
    chatStatePopup: CHAT_STATE_LOADING,
    keithSideCard: false,
  },
  reducers: {
    hide: state => {
      state.showPopup = false
      state.chatStateOverlay = CHAT_STATE_ANY
    },
    hidePopup: state => {
      state.showPopup = false
      state.chatStateOverlay = CHAT_STATE_ANY
    },
    hideChat: state => {
      state.chatStateOverlay = CHAT_STATE_HIDDEN
    },
    unhideChat: state => {
      state.chatState = CHAT_STATE_ANY
      state.chatStateOverlay = CHAT_STATE_ANY
    },
    showKeithSideCard: (state, action) => {
      state.keithSideCard = action.payload !== false
    },
    showPopup: (state, action) => {
      state.showPopup = true
      state.chatStateOverlay = CHAT_STATE_HIDDEN
      const { product, category } = action.payload
      state.category = category
      state.makeAnOffer = !!action.payload.makeAnOffer
      state.product = {
        title: action.payload.product.title,
        sku: action.payload.product.sku,
        image: {
          ...product.image,
        },
        price: action.payload.product.price,
        retail_price: action.payload.product.price,
        special_price: action.payload.product.special_price,
        qty: action.payload.product.qty,
        url: action.payload.product.url,
        productType: action.payload.product.productType,
      }
    },
    openChat: state => {
      state.showPopup = false
      state.chatState = CHAT_STATE_OPEN
      state.chatStateOverlay = CHAT_STATE_ANY
    },
    sendOffer: (state, action) => {
      state.offerSent = true
      state.makeAnOffer = true
      state.fullName = action.payload.name
      state.email = action.payload.email
      state.phone = action.payload.phone
      state.message = action.payload.message
      state.offerPrice = action.payload.offer
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  sendOffer,
  openChat,
  showPopup,
  hidePopup,
  hide,
  hideChat,
  unhideChat,
  showKeithSideCard,
} = popupForms.actions

export default popupForms.reducer
