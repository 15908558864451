import { configureStore } from '@reduxjs/toolkit'
import searchSlice from './search'
import cartSlice from './cart'
import checkoutSlice from './checkout'
import popupFormsSlice from './popupForms'
import { loadState } from './localStorage'
import taxApi from './taxApi'

const createStore = () => configureStore({
  preloadedState: loadState(),
  reducer: {
    cart: cartSlice,
    search: searchSlice,
    checkout: checkoutSlice,
    popupForms: popupFormsSlice
    // [taxApi.reducerPath]: taxApi.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(taxApi),
})


export default createStore
